import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import Faq from '../components/shared/FAQ/Faq'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import img from '../images/industry-sector/team_industry.svg'
import IndustryGrid from '../components/IndustrySector/IndustryGrid'
import icon1 from '../images/tax-credit/aerospace.svg'
import ip from '../images/ip.svg'
import copy from '../images/copy.svg'
import icon2 from '../images/tax-credit/cctv.svg'
import icon3 from '../images/tax-credit/paint.svg'
import icon4 from '../images/tax-credit/construction.svg'
import icon5 from '../images/tax-credit/electronics.svg'
import icon6 from '../images/tax-credit/engineering.svg'
import icon7 from '../images/tax-credit/materials.svg'
import icon8 from '../images/tax-credit/projects.svg'
import icon9 from '../images/tax-credit/sustainablity.svg'
import icon10 from '../images/tax-credit/pharmaceutical.svg'
import icon11 from '../images/tax-credit/testing.svg'
import { qs } from './claims-process'
import TextCols from '../components/TextCols/TextCols'
import rda from '../images/about-us/the_rda_way.svg'
import TwoCardSection from '../components/TwoCards/TwoCards'
import FaqAlt from '../components/shared/FAQ/FaqAlt'

export const qs1: Array<IQuestions> = [
  {
    question: 'When should my company conduct invention harvesting?',
    answer: (
      <>
        <p>
          A good place to introduce this is once a company has invested in
          Research &amp; Development (R&amp;D) and wants to ensure it captures
          and protects its valuable innovations.
        </p>
        <p>
          This needs to happen <strong>BEFORE</strong> commercialisation and
          public disclosure. Another time to do so is when strengthening IP
          strategy for the company.
        </p>
        <p>
          When building an IP portfolio to protect against competitors, attract
          investors, or create licensing opportunities, it is important to
          ensure you have considered all your valuable IP.
        </p>
      </>
    ),
  },
  {
    question: 'What are the benefits of invention harvesting?',
    answer: (
      <>
        <p>There are many reasons to go down this route, as follows:</p>
        <ul>
          <li>
            <div><strong>Additional tax relief:</strong><span style={{ fontWeight: '300' }}>  By patenting innovations in
            the UK and/or Europe, companies can claim tax benefits on profits
            derived from those patents through the Patent Box relief, reducing
            their effective tax rate.</span></div>
          </li>
          <li>
            <div><strong>Increasing Company Value:</strong><span style={{ fontWeight: '300' }}>  Patents and other
            catalogued and recorded IP can be added to the balance sheet and add
            to company valuation.</span></div>
          </li>
          <li>
            <div><strong>Maximising IP Opportunities:</strong><span style={{ fontWeight: '300' }}>  Ensuring all potential
            inventions are identified will reduce the risk of missing out on
            valuable, patentable ideas.</span></div>
          </li>
          <li>
            <div><strong>Enhancing Competitive Advantage:</strong><span style={{ fontWeight: '300' }}>  Protected
            innovations create barriers for competitors.</span></div>
          </li>
          <li>
            <div><strong>Increasing ROI on R&amp;D:</strong><span style={{ fontWeight: '300' }}>  Turning innovations
            into protected assets captures the value of R&amp;D investments.</span></div>
          </li>
          <li>
            <div><strong>Strategic IP Management:</strong><span style={{ fontWeight: '300' }}>  By strengthening the
            company's IP portfolio, you can enhance its valuation while also
            supporting licensing, partnerships, or sales.</span></div>
          </li>
        </ul>
      </>
    ),
  },
]

export const qs2: Array<IQuestions> = [
  {
    question: 'When would my company need a patent-landscaping exercise?',
    answer: (
      <>
        <p>
          There are several points in the product-development cycle that would benefit from turning attention to IP.
        </p>
        <ul>
          <li>
            <div><strong>During Research & Development:</strong><span style={{ fontWeight: '300' }}>  The best time to use a patent-landscaping service is at the start of any R&D project. If significant changes take place during the R&D process then they can be accommodated, however an update to the patent landscape would be needed.</span></div>
          </li>
          <li>
            <div><strong>When exploring New Markets or Technologies:</strong><span style={{ fontWeight: '300' }}>  To understand the competitive IP landscape before entering a new market or investing in a specific technology, patent landscaping is helpful.</span></div>
          </li>
          <li>
            <div><strong>For an IP Risk Assessment:</strong><span style={{ fontWeight: '300' }}>  When you want to avoid potential infringement risks and identify patents that could pose challenges to your products and processes, a patent-landscaping exercise is a vital step.</span></div>
          </li>
          <li>
            <div><strong>Patent Tracking:</strong><span style={{ fontWeight: '300' }}>  Monitor competitors' activities and emerging trends helps you keep abreast of developments in the industry.</span></div>
          </li>
        </ul>
 
      </>
    ),
  },
  {
    question: 'What are the benefits to patent landscaping?',
    answer: (
      <>
        <p>Again, there are many benefits to using a patent-landscaping service, such as:</p>
        <ul>
          <li>
            <div><strong>Strategic R&D Planning:</strong><span style={{ fontWeight: '300' }}>  A worthwhile step to identify gaps in existing patents and focus R&D efforts on areas with fewer IP barriers.</span></div>
          </li>
          <li>
            <div><strong>Baseline Technology:</strong><span style={{ fontWeight: '300' }}>  Helps a company fully understand the technology baseline before making an R&D tax credit claim.</span></div>
          </li>
          <li>
            <div><strong>Competitive Analysis:</strong><span style={{ fontWeight: '300' }}>  Insights into competitors' patent portfolios and strategies are important.</span></div>
          </li>
          <li>
            <div><strong>IP Strategy Development:</strong><span style={{ fontWeight: '300' }}>  For licensing, acquisition, or collaboration opportunities.</span></div>
          </li>
          <li>
            <div><strong>Cost Efficiency:</strong><span style={{ fontWeight: '300' }}>  The risk of wasted R&D efforts on technologies that are already patented can be reduced.</span></div>
          </li>
          <li>
            <div><strong>Regulatory and Market Insights:</strong><span style={{ fontWeight: '300' }}>  Offers a clearer understanding of global patent trends and jurisdictional differences.</span></div>
          </li>
          
        </ul>
      </>
    ),
  },
]

export const qs3: Array<IQuestions> = [
  {
    question: 'When should my company consider an FTO?',
    answer: (
      <>
        <ul>
          <li>
            <div><strong>Before Launching a Product or Service:</strong><span style={{ fontWeight: '300' }}>  To ensure the product or process does not infringe on active third-party IP rights.</span></div>
            </li>
          <li>
            <div><strong>Expanding to New Markets:</strong><span style={{ fontWeight: '300' }}>  Whether you are looking to sell into a new market or open a new manufacturing plant, you need to ensure that your development will not infringe the IP rights in force there.</span></div>
            </li>
          <li>
            <div><strong>Before Securing Investment or Partnerships:</strong><span style={{ fontWeight: '300' }}>  Investors or partners may require assurances that there are no IP risks.</span></div>
            </li>
          <li>
            <div><strong>When Developing New Technology:</strong><span style={{ fontWeight: '300' }}>  Particularly in industries with high patent activity, such as pharmaceuticals, medtech or biotech.</span></div>
            </li>
        </ul>
 
      </>
    ),
  },
]


const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Intellectual Property Services',
    url: '/intellectual-property-services',
  },
]


const IndustrySector = () => {
  return (
    <Layout
      activeLink="/intellectual-property-services"
      title="Intellectual intellectual-property-services Services"
      description="R&D Our Intellectual Property Services"
    >
      <GeneralHeader
        breadcrumbData={breadcrumbData}
        heading="Our Intellectual Property Services"
      />

      <ImageAndTextColumns
        tag=""
        heading="Invention Harvesting"
        body={[
          'Invention Harvesting is a service that helps organisations systematically identify, evaluate, and document innovative ideas, processes, or products. This is an important step to take, because you may find they are eligible for IP protection such as patents.',
         <div>
      <h3 style={{ fontSize: '1.8rem', lineHeight: '2.5rem' }}>In essence Invention Harvesting is a service that seeks to answer three questions:</h3>
      <ul style={{ paddingTop: '20px' }}>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight: '300', fontSize:'1.8rem'}}>
              Do you have something patentable within your business?
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px', fontSize:'1.8rem' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight: '300'}}>
              Is it WORTH patenting that something?
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px', fontSize:'1.8rem' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight:'300'}}>
              If not, what is the best way I can protect my innovation?
            </span>
          </div>
        </li>
      </ul>
    </div>,

  ]}
        img={ip}
        imgAlt="ip protection"
      />

      
      <FaqAlt questions={qs1} />

      <ImageAndTextColumns
        tag=""
        heading="Patent Landscaping"
        body={[
          'Patent Landscaping is a comprehensive analysis of the patent landscape within a specific technology area, industry, or geographic region. It identifies existing patents, trends, and opportunities to support strategic decision-making.',


  ]}
        img={img}
        imgAlt="Patent Landscaping"
      />

      
      <FaqAlt questions={qs2} />

           <ImageAndTextColumns
        tag=""
        heading="Freedom to Operate"
        body={[
          'Freedom to Operate (FTO) is a service that assesses whether a company can commercialise a product or process without infringing on existing IP rights, such as patents or designs.',
         <div>
      <h3 style={{ fontSize: '1.8rem', lineHeight: '2.5rem' }}>Key considerations:</h3>
      <ul style={{ paddingTop: '20px' }}>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight: '300', fontSize:'1.8rem'}}>
              FTO is particularly important for companies in innovation-driven industries and those planning international operations.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px', fontSize:'1.8rem' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight: '300'}}>
              Obtaining a patent on your product or service does not automatically mean you have FTO; you still need to assess the third-party IP held in your territories of interest.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px', fontSize:'1.8rem' }}>
          <div style={{ flexDirection: 'column' }}>
            <span style={{fontWeight:'300'}}>
              Not wanting a patent does not make you immune to patent infringement. If you do not desire a patent on your product, you can still infringe patents held by other companies.
            </span>
          </div>
        </li>
      </ul>
    </div>,

  ]}
        img={copy}
        imgAlt="Freedom to Operate"
      />

      <FaqAlt questions={qs3} />

      <CallBackBanner />
    </Layout>
  )
}

export default IndustrySector
